
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        



































































$bp: m;

.creation-gallery {
  position: relative;
}

.creation-gallery__title {
  @extend %text-center;

  margin-bottom: var(--spacing-m);
}

.creation-gallery__carousel.carousel {
  --scrollbar-width: 0;

  ::v-deep .carousel__controls {
    justify-content: flex-start;
    margin-top: 0;
  }

  @include mq($bp) {
    ::v-deep .carousel__scroller {
      flex-wrap: wrap;
      gap: 0 var(--slide-gap);
    }

    ::v-deep .carousel__scroller > li {
      width: calc(calc(100% - calc(3 * var(--slide-gap))) / 4);
    }

    ::v-deep .carousel__controls {
      display: none;
    }
  }

  @include mq(xl) {
    ::v-deep .carousel__scroller > li {
      width: calc(calc(100% - calc(4 * var(--slide-gap))) / 5);
    }
  }
}

.creation-gallery__item {
  width: 24rem;
  max-width: 80vw;
  padding-bottom: 3rem;

  @include mq($bp) {
    width: 100%;
    padding-bottom: 6rem;
  }
}
