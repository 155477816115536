
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





































































$bp: m;

.creation-card {
  position: relative;
  display: flex;
  flex-direction: column;
  transform: translateX(0);

  @include mq($bp) {
    &.highlighted {
      padding-bottom: 10rem;
    }
  }
}

.creation-card__content {
  .creation-card.highlighted & {
    @extend %border-radius-small;

    z-index: 1;
    margin: -7rem var(--wrapper-padding) 0;
    padding: var(--spacing-s);
    background: $c-white;
    box-shadow: $card-shadow;
  }

  @include mq($bp) {
    .creation-card.highlighted & {
      position: absolute;
      bottom: 0;
      left: 4rem;
      width: calc(100% - 8rem);
      margin: 0;
    }
  }
}

.creation-card__content__title {
  text-decoration: none;

  .creation-card:not(.highlighted) & h2 {
    @extend %ff-default;
    @extend %text-center;

    padding: var(--spacing-s) var(--spacing-xs) 0;
    font-size: 1.6rem;
  }

  &::after {
    @include get-all-space;

    content: '';
    position: fixed;
    z-index: 1;
  }
}

.creation-card__content__text {
  margin: $spacing * 0.4 0 var(--spacing-xs);
}

.creation-card__content__arrow {
  color: $c-mustard;
  transition: transform 0.3s $ease-softer;

  .creation-card__content__title:hover ~ &,
  .creation-card__content__title:focus-visible ~ & {
    transform: translateX(0.5rem);
  }
}

.creation-card__picture {
  @extend %border-radius-small;

  aspect-ratio: 2/1;
  order: -1;
  overflow: hidden;
  box-shadow: $card-shadow;

  .creation-card:hover &,
  .creation-card:focus-within & {
    ::v-deep .picture__container__inner {
      transform: scale(1.1);
    }
  }

  .creation-card.highlighted & {
    aspect-ratio: 3/2;
  }

  @include mq($bp) {
    .creation-card.highlighted & {
      border-radius: 2rem;
    }
  }
}
